import { useState, useRef, useEffect } from 'react';
import { graphql } from "gatsby"
import type { PageProps } from "gatsby"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbySeo } from "gatsby-plugin-next-seo";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Unstable_Grid2';
import { LinkForI18n as Link } from "../components/from-gatsby-theme-material-ui/link"
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const SCROLL_INTERVAL = 30;

const IndexPage = ({ data }: PageProps<Queries.TopIndexQuery>) => {
  const fNodes = data.allCloudinaryFolder.nodes;

  const [scrollData, setScrollData] = useState(fNodes.slice(0, SCROLL_INTERVAL));
  const [hasMoreValue, setHasMoreValue] = useState(fNodes.length > SCROLL_INTERVAL);

  const handleOnRowsScrollEnd = () => {
    if (scrollData.length < fNodes.length) {
      setHasMoreValue(true);
      setScrollData(fNodes.slice(0, scrollData.length + (SCROLL_INTERVAL / 3)));
    } else {
      setHasMoreValue(false);
    }
  };

  const infiniteBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((!infiniteBoxRef.current) || fNodes.length <= SCROLL_INTERVAL) return;
    if (infiniteBoxRef.current?.offsetHeight < window.innerHeight) {
      handleOnRowsScrollEnd();
    }

  }, [scrollData.length])
  const { t } = useTranslation();
  return (
    <>
      <GatsbySeo
        title={t("topTitle")}
        description={t("topDesc")}
        openGraph={{
          title: "Top Page",
          description: "Top Page of the Site"
        }} />
      <Box>
        <Typography
          variant="h3"
          component="h1"
          my="20px"
          fontSize={{ xs: 28, md: 36 }}
        >
          <Trans i18nKey="topPageMainHeader">
            Today's Super Smash Bros. Ultimate Screenshot Images Gallery
          </Trans>
        </Typography>
        <ImageGallery
          items={[
            {
              original: "https://res.cloudinary.com/ddre30sf7/image/upload/v1669537152/ssbu-images/20221126/0LoLaEu_nnkwh3.jpg",
              originalHeight: 600,
              loading: 'lazy'
            },
            {
              original: "https://res.cloudinary.com/ddre30sf7/image/upload/v1669537151/ssbu-images/20221126/0grFIyY_eycdqd.jpg",
              originalHeight: 600,
              loading: 'lazy'
            },
            {
              original: "https://res.cloudinary.com/ddre30sf7/image/upload/v1669537151/ssbu-images/20221127/0ewa20G_pymyna.jpg",
              originalHeight: 600,
              loading: 'lazy'
            },
            {
              original: "https://res.cloudinary.com/ddre30sf7/image/upload/v1669537150/ssbu-images/20221127/00dF7mZ_swfsam.jpg",
              originalHeight: 600,
              loading: 'lazy'
            },
            {
              original: "https://res.cloudinary.com/ddre30sf7/image/upload/v1669537151/ssbu-images/20221127/0eFTT9Y_ohfros.jpg",
              originalHeight: 600,
              loading: 'lazy'
            },
          ]}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showBullets={true}
          lazyLoad={true}
        ></ImageGallery>
        <Box ref={infiniteBoxRef}>
          <InfiniteScroll
            dataLength={scrollData.length}
            next={handleOnRowsScrollEnd}
            hasMore={hasMoreValue}
            loader={<LinearProgress />}
            style={{ overflow: "unset" }}
          >
            <Grid container spacing={2} my="20px">
              {scrollData.map((cfolder) => {
                return (
                  <Grid key={cfolder.id} xs={12} sm={6} md={4}>
                    <Paper sx={{ height: { xs: "40px", md: "80px" } }} >
                      <Link
                        to={`/gallery/${cfolder.foldername}`}
                        underline='none'
                        display="flex"
                        height="100%"
                        sx={{
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Box>
                          {t("imageOfTheDate",
                            {
                              val: moment(new Date(cfolder.dateFromFolderName || "")).toDate(),
                              formatParams: {
                                val: { year: 'numeric', month: 'long', day: 'numeric' },
                              }
                            })}
                        </Box>
                      </Link>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>

          </InfiniteScroll>
        </Box>
      </Box>
    </>
  )
}

export default IndexPage

// export const Head: HeadFC = () => <title>Home Page</title>

export const query = graphql`
  query TopIndex($language: String!) {
    allCloudinaryFolder(sort: {fields: foldername, order: DESC}) {
      nodes {
        id
        foldername
        dateFromFolderName
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    localesLayout: allLocale(
      filter: {ns: {eq: "layout"}, language: {eq: $language}}
    ) {
      nodes {
        data
        language
      }
    }
  }
`
